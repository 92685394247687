import type {
    TravelFormActivityResult,
    TravelFormDataV1Attributes,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';
import type { TravelFormFilterValue } from '@/features/travel-form/travel-form-data-v1/use-travel-form-data-v1';
import type { TravelFormFilterName } from '@/features/travel-form/travel-form-input-type';

import TravelFormActivityOverlay from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/travel-form-activity-overlay';
import useTravelFormActivityOverlay from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/use-travel-form-activity-overlay';

type TravelFormActivityOverlayContainerProps = {
    activity: TravelFormDataV1Attributes['filters']['activity'];
    activityRequestTarget: string;
    onClose: () => void;
    onOverlayCtaClick: (
        filterName: TravelFormFilterName,
        filterValue: TravelFormFilterValue,
        requestTarget?: string,
    ) => void;
};

export default function TravelFormActivityOverlayContainer({
    activity,
    activityRequestTarget,
    onClose,
    onOverlayCtaClick,
}: TravelFormActivityOverlayContainerProps) {
    const { isLoading, onActivityClick, results, selectedActivities, selectedActivitiesByName } =
        useTravelFormActivityOverlay({
            activityRequestTarget,
            selectedItems: activity?.selectedItems,
            selectionType: activity?.selectionType,
        });

    if (!activity) {
        return null;
    }

    const handleActivityClick = (activityItem: TravelFormActivityResult) => {
        onActivityClick(activityItem, () => onOverlayCtaClick(activity.name, activityItem.name, activityRequestTarget));
    };

    const onSubmit = () => {
        if (selectedActivities.length === 0) {
            onOverlayCtaClick(activity.name, null, activityRequestTarget);
            return;
        }
        onOverlayCtaClick(activity.name, selectedActivitiesByName.join(','), activityRequestTarget);
    };

    return (
        <TravelFormActivityOverlay
            activities={results ?? []}
            ctaText={selectedActivities.length > 1 ? activity.ctaText.plural : activity.ctaText.singular}
            isLoading={isLoading}
            isMultiSelect={activity.selectionType === 'multiple'}
            onActivityClick={handleActivityClick}
            onClose={onClose}
            onSubmit={onSubmit}
            selectedActivities={selectedActivitiesByName}
            title={activity.title}
        />
    );
}
