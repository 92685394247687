import type {
    TravelFormActivityResult,
    TravelFormDataV1_jsonld_page_read,
    TravelFormFilterSelectionType,
    TravelFormSelectedActivity,
} from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import React from 'react';

import useComponentDataByIri from '@/core/features/a-component/hooks/use-component-data-by-iri';

export default function useTravelFormActivityOverlay({
    activityRequestTarget,
    selectedItems,
    selectionType,
}: {
    activityRequestTarget: string | undefined;
    selectedItems: TravelFormSelectedActivity[] | undefined;
    selectionType: TravelFormFilterSelectionType | undefined;
}) {
    const [targetComponentIri, setTargetComponentIri] = React.useState<null | string>(activityRequestTarget ?? null);
    const { data, isLoading } = useComponentDataByIri<TravelFormDataV1_jsonld_page_read>(targetComponentIri, {
        queryName: 'travelFormActivityOverlay',
    });

    const [selectedActivities, setSelectedActivities] = React.useState<TravelFormSelectedActivity[]>(
        selectedItems ?? [],
    );

    const onActivityClick = (activityItem: TravelFormActivityResult, onSingleSelectClick?: () => void) => {
        if (selectionType === 'single') {
            onSingleSelectClick?.();
            return;
        }

        const activityNames = selectedActivities.map((activity) => activity.name);

        if (activityNames.includes(activityItem.name)) {
            setSelectedActivities((prev) => prev.filter((activity) => activity.name !== activityItem.name));
        } else {
            setSelectedActivities((prev) => [...prev, { name: activityItem.name, title: activityItem.title }]);
        }
    };

    // using this effect to set the targetComponentIri once travelFormDataV1 is available
    React.useEffect(() => {
        if (!!activityRequestTarget && !targetComponentIri) {
            setTargetComponentIri(activityRequestTarget);
        }
        return () => {};
    }, [activityRequestTarget, targetComponentIri]);

    return {
        isLoading,
        onActivityClick,
        results: data?.attributes?.filters.activity?.resultSet,
        selectedActivities,
        selectedActivitiesByName: selectedActivities.map((activity) => activity.name),
    };
}
