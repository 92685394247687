import type { TravelFormActivityResult } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import TravelFormActivity from '@/features/travel-form/travel-form-overlay/travel-form-activity-overlay/travel-form-activity';
import TravelFormOverlayWrapper from '@/features/travel-form/travel-form-overlay/travel-form-overlay-wrapper/travel-form-overlay-wrapper';

type TravelFormActivityOverlayProps = {
    activities: TravelFormActivityResult[];
    ctaText: string;
    isLoading?: boolean;
    isMultiSelect: boolean;
    onActivityClick: (activityItem: TravelFormActivityResult) => void;
    onClose: () => void;
    onSubmit: () => void;
    selectedActivities: string[];
    title: string;
};

export default function TravelFormActivityOverlay({
    activities,
    ctaText,
    isLoading,
    isMultiSelect,
    onActivityClick,
    onClose,
    onSubmit,
    selectedActivities,
    title,
}: TravelFormActivityOverlayProps) {
    return (
        <TravelFormOverlayWrapper
            isCtaDisabled={false}
            isLoading={isLoading}
            onClose={onClose}
            onSubmit={onSubmit}
            renderCtaText={ctaText}
            title={title}
        >
            <TravelFormActivity
                activities={activities}
                isMultiSelect={isMultiSelect}
                onActivityClick={onActivityClick}
                selectedActivities={selectedActivities}
            />
        </TravelFormOverlayWrapper>
    );
}
