import type { OnClickEvent } from '@/core/types/react-overrides';
import type { TravelFormActivityResult } from '@/features/travel-form/travel-form-data-v1/travel-form-data-v1-type';

import CheckBox from '@/core/components/check-box/check-box';
import { IconImage } from '@/core/features';

import styles from './travel-form-activity.module.scss';

type TravelFormActivityProps = {
    activities: TravelFormActivityResult[];
    isMultiSelect: boolean;
    onActivityClick: (activityItem: TravelFormActivityResult) => void;
    selectedActivities: string[];
};

export default function TravelFormActivity({
    activities,
    isMultiSelect,
    onActivityClick,
    selectedActivities,
}: TravelFormActivityProps) {
    const onClick = (event: OnClickEvent, activityItem: TravelFormActivityResult) => {
        event.stopPropagation();
        onActivityClick(activityItem);
    };
    return (
        <div className={styles.travelFormActivity}>
            {activities.map((activity, index) => (
                <div
                    className={styles.activityItem}
                    data-qa-id={'qa-travel-form-overlay-activity-item'}
                    key={`${activity.title}-${index}`}
                    onClick={(event) => onClick(event, activity)}
                >
                    <div className={'flex gap-10 align-center'}>
                        <IconImage
                            height={25}
                            url={activity.iconUrl}
                            width={25}
                        />
                        <div>{activity.title}</div>
                    </div>
                    {isMultiSelect ? (
                        <CheckBox
                            isSelected={selectedActivities.includes(activity.name)}
                            onClick={(event) => onClick(event, activity)}
                        />
                    ) : (
                        <div className={`flex-center ${styles.activityItemCount}`}>{activity.count}</div>
                    )}
                </div>
            ))}
        </div>
    );
}
