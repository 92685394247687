import React from 'react';

import { Button, ButtonWrapper, LoopedLoadingBar, SlideUpOverlay } from '@/core/features';

import styles from './travel-form-overlay-wrapper.module.scss';

type TravelFormOverlayWrapperProps = {
    isLoading?: boolean;
    onClose: () => void;
    title: string;
} & (
    | {
          isCtaDisabled: boolean;
          isCtaHidden?: never;
          onSubmit: () => void;
          renderCtaText: React.ReactElement | string;
      }
    | {
          isCtaDisabled?: never;
          isCtaHidden: boolean;
          onSubmit?: never;
          renderCtaText?: never;
      }
);

export default function TravelFormOverlayWrapper({
    children,
    isCtaDisabled = false,
    isCtaHidden,
    isLoading,
    onClose,
    onSubmit,
    renderCtaText,
    title,
}: React.PropsWithChildren<TravelFormOverlayWrapperProps>) {
    return (
        <SlideUpOverlay
            onClose={onClose}
            title={title}
            titleStyle={'bold'}
        >
            {isLoading && <LoopedLoadingBar />}
            <div className={styles.overlayContent}>{children}</div>
            {!isCtaHidden && (
                <div className={styles.submitButtonContainer}>
                    {typeof renderCtaText === 'string' ? (
                        <Button
                            disabled={isCtaDisabled}
                            onClick={onSubmit}
                            qaId={'qa-travel-form-overlay-submit-button'}
                            title={renderCtaText}
                        />
                    ) : (
                        <ButtonWrapper
                            disabled={isCtaDisabled}
                            onClick={onSubmit}
                            qaId={'qa-travel-form-overlay-submit-button'}
                        >
                            {renderCtaText}
                        </ButtonWrapper>
                    )}
                </div>
            )}
        </SlideUpOverlay>
    );
}
